.sidebar-wrapper {
    background-color: #f5f5f5;
    padding: 20px;
    border-right: 1px solid #ddd;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }
  
  .sidebar-header {
    font-size: 1.5rem;
    margin-bottom: 20px;
    font-weight: bold;
    color: #17a2b8;
  }
  
  .semester-section {
    margin-bottom: 20px; 
  }
  
  .semester-header {
    display: flex;
    align-items: center;
    font-size: 1.25rem;
    cursor: pointer;
    padding: 15px; 
    background-color: #f5f5f5;
    border-radius: 4px;
    transition: background-color 0.3s;
  }
  
  .semester-header:hover {
    background-color: #e2e6ea;
  }
  
  .semester-header .me-2 {
    margin-right: 15px;
  }
  
  .semester-header .ms-auto {
    margin-left: auto;
    padding-left: 10px; 
  }
  
  .semester-modules {
    padding-left: 20px;
    margin-top: 10px;
  }
  
  .semester-modules li a {
    color: #6c757d;
    font-size: 1rem;
    padding: 5px 0;
    display: block;
  }
  
  .semester-modules li a:hover {
    color: #17a2b8;
    text-decoration: underline;
  }
  
 
.sidebar-wrapper {
    background-color: #f5f5f5;
    padding: 20px;
    border-right: 1px solid #ddd;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }
  
 
.mobile-navbar {
    display: none;
    width: 100%;
    background-color: #17a2b8;
    padding: 10px;
    color: white;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1000;
    display: flex;
    align-items: center;
    justify-content: space-between;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }
  
  
  .hamburger-menu {
    background: none;
    border: none;
    color: white;
    font-size: 1.5rem;
    cursor: pointer;
  }
  
  .navbar-title {
    margin: 0;
    font-size: 1.5rem;
  }
  
 
  @media (max-width: 991px) {
    .hamburger-menu {
      display: block;
    }
  
    .sidebar-wrapper {
      position: fixed;
      top: 50px; 
      left: 0;
      height: calc(100vh - 50px); 
      width: 250px;
      background: #f5f5f5;
      z-index: 999;
      transform: translateX(-100%);
      transition: transform 0.3s ease-in-out;
      padding-top: 20px;
      box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
      overflow-y: auto;
    }
  
    .sidebar-wrapper.open {
      transform: translateX(0);
    }
  
    .main-content {
      padding-top: 70px; 
    }
  
    .semester-header {
      padding: 10px;
    }
  
    .semester-section {
      margin-bottom: 15px;
    }
  }

  .form-control {
    border-radius: 30px; 
    padding: 10px 20px; 
    border: 1px solid #ddd;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.1);
    transition: box-shadow 0.3s ease, border-color 0.3s ease;
  }
  
  .form-control:focus {
    border-color: #17a2b8; 
    box-shadow: 0 4px 8px rgba(23, 162, 184, 0.4); 
  }
  
  
  .form-select {
    border-radius: 20px;
    padding: 10px 20px; 
    border: 1px solid #ddd; 
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.1); 
    transition: box-shadow 0.3s ease, border-color 0.3s ease;
  }
  
  .form-select:focus {
    border-color: #17a2b8;
    box-shadow: 0 4px 8px rgba(23, 162, 184, 0.4); 
  }
  
  
 
  .sidebar-wrapper.open {
    top: 50px;
  }
  
 
  @media (min-width: 992px) {
    .mobile-navbar {
      display: none;
    }
  }
  