.admin-panel {
    background-color: #f9f9f9;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  }
  
  .admin-panel h2 {
    font-size: 2rem;
    font-weight: 600;
    color: #333;
  }
  
  .table-header {
    background-color: #007bff;
    color: white;
  }
  
  .table thead th {
    font-size: 1rem;
    font-weight: 600;
    text-align: center;
  }
  
  .table tbody tr td {
    text-align: center;
  }
  
  .table .btn {
    margin: 0 5px;
  }
  
  .shadow-sm {
    box-shadow: 0 1px 4px rgba(0, 0, 0, 0.1);
  }
  
  .btn i {
    margin-right: 5px;
  }
  
  .btn-primary, .btn-success {
    font-weight: 600;
  }
  
  .container {
    max-width: 1200px;
  }
  