body {
  font-family: 'Helvetica Neue', Arial, sans-serif;
  color: #6c757d;
  margin: 0;
  padding: 0;
}

.hero-section {
  position: relative;

  background-size: cover;
  background-position: center;
  height: 350px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.hero-section::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  z-index: 1;
}

.hero-section h1 {
  color: white;
  font-size: 2.5rem;
  font-weight: 300;
  z-index: 2;
}

.hero-section input {
  z-index: 2;
  margin-top: 15px;
  max-width: 500px;
  width: 100%;
  padding: 10px;
  border-radius: 0.25rem;
  border: none;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.app-container {
  display: flex;
}

.sidebar {
  width: 250px;
  background-color: #f8f9fa;
  padding: 20px;
  position: sticky;
  top: 0;
  height: calc(100vh - 350px); 
  overflow-y: auto;
 
}

.main-content {
  flex: 1;
  padding: 20px;
  background-color: #f5f5f5;
}


.container {
  max-width: 1140px;
  margin: 0 auto;
}

.card {
  border: none;
  border-radius: 0.75rem;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  margin-bottom: 20px;
}

.card-header {
  background-color: #17a2b8;
  color: white;
  padding: 15px;
  border-bottom: none;
  font-weight: lighter;
  font-size: 1.25rem;
  
}
.card-header .me-2 {
  margin-right: 15px; 
  font-size: 1.5rem;
}

.rounded-card {
  border-radius: 0.75rem !important;
  overflow: hidden; 
}

.card-body {
  background-color: #f8f9fa;
  padding: 20px;
}

.list-group-item {
  background-color: #f8f9fa;
  border: none;
  padding: 10px 0;
}

.list-group-item i {
  color: #17a2b8;
  margin-right: 10px;
}

/* Für kleinere Bildschirme */
@media (max-width: 991px) {
  .sidebar {
    width: 100%;
    position: static;
    height: auto;
    box-shadow: none;
    margin-bottom: 20px;
  }

  .main-content {
    padding: 20px;
  }
}
