/* ModuleForm.css */
.form-container {
    max-width: 700px;
    margin: 0 auto;
    padding: 30px;
    background-color: #f9f9f9;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  .form-container h2 {
    text-align: center;
    margin-bottom: 20px;
    color: #333;
  }
  
  .form-control {
    border-radius: 4px;
    padding: 12px;
    margin-bottom: 15px;
  }
  
  .button-group {
    display: flex;
    justify-content: space-between;
  }
  
  
  
  .btn-secondary {
    background-color: #6c757d;
    border-color: #6c757d;
    padding: 10px 20px;
    font-size: 16px;
  }
  